import React from 'react'
import About from './components/About'
import Footer from './components/Footer'
import Header from './components/Header'
import Hero from './components/Hero'
import Menu from './components/Menu/Menu'
import TopBar from './components/TopBar'
import Contact from './components/Contact'
import SocialMediaFloat from './components/SocialMediaFloat'

function App() {
  const [isMobileNavOpen, setIsMobileNavOpen] = React.useState(false)
  return (
    <>
      <TopBar />
      <Header
        isMobileNavOpen={isMobileNavOpen}
        setIsMobileNavOpen={setIsMobileNavOpen}
      />
      {!isMobileNavOpen && <SocialMediaFloat />}
      <Hero />
      <main id="main">
        <About />
        <Menu />
      </main>
      <Contact />
      <Footer />
    </>
  )
}

export default App
