export const MENU_ITEMS = Object.freeze({
  ALL: {
    key: 'all',
    label: 'All'
  },
  BURGERS: {
    key: 'burgers',
    label: 'Burgers',
    count: 8
  },
  PITAS: {
    key: 'pitas',
    label: 'pitas',
    count: 7
  },
  ROLLS: {
    key: 'rolls',
    label: 'rolls',
    count: 7
  },
  SPECIALS: {
    key: 'specials',
    label: 'Specials',
    count: 2
  },
  SALADS: {
    key: 'salads',
    label: 'Salads',
    count: 5
  },
  SIDE_DISHES: {
    key: 'sides',
    label: 'Side dishes',
    count: 5
  }
})
