import React from 'react'
import { useTranslation } from 'react-i18next'
import { MENU_ITEMS } from '../../utils'

export default function MenuNav() {
  const { t } = useTranslation()
  return (
    <div className="row" data-aos="fade-up" data-aos-delay="100">
      <div className="col-lg-12 d-flex menu-nav">
        <ul id="menu-flters">
          {Object.values(MENU_ITEMS).map((item) => {
            return (
              <li
                data-filter={item.key === 'all' ? '*' : `.filter-${item.key}`}
                className={item.key === 'all' ? 'filter-active' : ''}
                key={item.key}
              >
                {t(`${item.key}-menu-title`)}
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}
