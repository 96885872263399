import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { Divider } from 'antd'

// eslint-disable-next-line react/prop-types
const MobileNav = ({ toggleMobileNav }) => {
  const { t } = useTranslation()
  // NOTE: You also need to provide styles, see https://github.com/negomi/react-burger-menu#styling
  return (
    <StyledMenu noOverlay>
      <ul className="i18n-he">
        <li onClick={toggleMobileNav}>
          <a className="nav-link scrollto active" href="#hero">
            {t('nav-btn-home')}
          </a>
        </li>
        <StyledDivider />
        <li onClick={toggleMobileNav}>
          <a className="nav-link scrollto" href="#about">
            {t('nav-btn-about')}
          </a>
        </li>
        <StyledDivider />
        <li onClick={toggleMobileNav}>
          <a className="nav-link scrollto" href="#menu">
            {t('nav-btn-menu')}
          </a>
        </li>
        <StyledDivider />
        <li onClick={toggleMobileNav}>
          <a className="nav-link scrollto" href="#contact">
            {t('nav-btn-contact')}
          </a>
        </li>
        <StyledDivider />
        <li>
          <a
            className="mobile-nav-facebook"
            href="https://www.facebook.com/profile.php?id=100089811567852"
            target="_blank"
            rel="noreferrer"
          >
            Facebook
          </a>
        </li>
        <StyledDivider />
        <li>
          <a
            href="https://www.instagram.com/bbq.house.haifa/"
            className="mobile-nav-instagram"
            target="_blank"
            rel="noreferrer"
          >
            Instagram
          </a>
        </li>
        <StyledDivider />
        <li>
          <a
            href="https://wolt.com/he/isr/haifa/restaurant/bbq-house-haifa"
            className="mobile-nav-wolt"
            target="_blank"
            rel="noreferrer"
          >
            Wolt
          </a>
        </li>
        <StyledDivider />
        <li>
          <a
            href="https://www.10bis.co.il/next/restaurants/menu/delivery/38996/bbq-house"
            className="mobile-nav-tenbis"
            target="_blank"
            rel="noreferrer"
          >
            10bis
          </a>
        </li>
        <StyledDivider />
        <li>
          <a
            href="https://ul.waze.com/ul?ll=32.81863012%2C34.99158382&navigate=yes&zoom=17&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location"
            className="mobile-nav-waze"
            target="_blank"
            rel="noreferrer"
          >
            Waze
          </a>
        </li>
      </ul>
    </StyledMenu>
  )
}

const StyledMenu = styled('div')`
  position: absolute;
  width: 100vw;
  background: rgba(0, 0, 0, 0.9);
  top: 66px;
  height: 100vh;
  z-index: 100;
  ul {
    list-style: none;
    padding: 0;
    padding-top: 60px;
    margin: 0;
    li {
      a {
        display: block;
        padding: 10px;
        text-decoration: none;
        color: #fff;
        font-size: 1.5rem;
        text-align: center;
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
  .mobile-nav-instagram {
    background: #f09433;
    background: -moz-linear-gradient(
      45deg,
      #f09433 0%,
      #e6683c 25%,
      #dc2743 50%,
      #cc2366 75%,
      #bc1888 100%
    );
    background: -webkit-linear-gradient(
      45deg,
      #f09433 0%,
      #e6683c 25%,
      #dc2743 50%,
      #cc2366 75%,
      #bc1888 100%
    );
    background: linear-gradient(
      45deg,
      #f09433 0%,
      #e6683c 25%,
      #dc2743 50%,
      #cc2366 75%,
      #bc1888 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
  }
  .mobile-nav-facebook {
    background: #3b5998;
    background: -moz-linear-gradient(top, #3b5998, #2b4170);
    background: -ms-linear-gradient(top, #3b5998, #2b4170);
    background: -webkit-linear-gradient(top, #3b5998, #2b4170);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3b5998', endColorstr='#9b59b6',GradientType=1 );
  }
  .mobile-nav-wolt {
    background: rgb(0, 157, 224);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f5a623', endColorstr='#f47b20',GradientType=1 );
  }
  .mobile-nav-tenbis {
    background: #f5a623;
    background: -moz-linear-gradient(top, #f5a623, #f47b20);
    background: -ms-linear-gradient(top, #f5a623, #f47b20);
    background: -webkit-linear-gradient(top, #f5a623, #f47b20);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f5a623', endColorstr='#f47b20',GradientType=1 );
  }
  .mobile-nav-waze {
    background: #05c8f7;
    background: -moz-linear-gradient(top, #05c8f7, #0037ff);
    background: -ms-linear-gradient(top, #05c8f7, #0037ff);
  }
`
const StyledDivider = styled(Divider)`
  margin: 0;
  background: #d9ba85;
`

export default MobileNav
