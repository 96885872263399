import React from 'react'

export default function Footer() {
  return (
    <footer id="footer">
      <div className="container">
        <div className="copyright">
          &copy; Copyright{' '}
          <strong>
            <span>BBQ HOUSE</span>
          </strong>{' '}
          2023
          <br /> All Rights Reserved
        </div>
      </div>
    </footer>
  )
}
