import React from 'react'
import { useTranslation } from 'react-i18next'

export default function Hero() {
  const { t } = useTranslation()
  return (
    <section id="hero" className="d-flex align-items-center">
      <div className="container position-relative text-center text-lg-start">
        <div className="row">
          <div className="col-lg-8">
            <h1 data-aos="zoom-in" data-aos-delay="100">
              Welcome to <span>BBQ HOUSE</span>
            </h1>
            <h2 className="slogan" data-aos="zoom-in" data-aos-delay="400">
              MEAT US!
            </h2>
            <p className="address" data-aos="zoom-in" data-aos-delay="700">
              <a
                href="https://www.google.com/maps/dir//BBQ+HOUSE+Derech+Allenby+51+Haifa,+35055/@32.8188677,34.9913641,7z/data=!4m8!4m7!1m0!1m5!1m1!1s0x151dbb11dff03a89:0xa43133e8ffb83bec!2m2!1d34.9913641!2d32.8188677"
                target="_blank"
                rel="noreferrer"
              >
                {t('hero-address')}
              </a>
            </p>
            <div className="btns">
              <a href="#menu" className="btn-menu animated fadeInUp scrollto">
                {t('nav-btn-menu')}
              </a>
              <a
                href="#contact"
                className="btn-book animated fadeInUp scrollto"
              >
                {t('nav-btn-contact')}
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
