import React from 'react'
import styled from '@emotion/styled'
export default function SocialMediaFloat() {
  return (
    <StyledContainer>
      <div className="social-links">
        <a
          href="https://www.facebook.com/profile.php?id=100089811567852"
          className="facebook"
          target="_blank"
          rel="noreferrer"
        >
          <i className="bx bxl-facebook"></i>
        </a>

        <a
          href="https://www.instagram.com/bbq.house.haifa/"
          className="instagram"
          target="_blank"
          rel="noreferrer"
        >
          <i className="bx bxl-instagram"></i>
        </a>

        <a
          href="https://wolt.com/he/isr/haifa/restaurant/bbq-house-haifa"
          className="wolt"
          target="_blank"
          rel="noreferrer"
        >
          <i className="bx bxl-wolt"></i>
        </a>

        <a
          href="https://www.10bis.co.il/next/restaurants/menu/delivery/38996/bbq-house"
          className="tenbis"
          target="_blank"
          rel="noreferrer"
        >
          <i className="bx bxl-tenbis"></i>
        </a>

        <a
          href="https://ul.waze.com/ul?ll=32.81863012%2C34.99158382&navigate=yes&zoom=17&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location"
          className="waze"
          target="_blank"
          rel="noreferrer"
        >
          <i className="bx bxl-waze"></i>
        </a>
      </div>
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  padding-left: 15px;
  bottom: 15px;
  z-index: 996;
  height: 44px;
  z-index: 999;
  border-radius: 0 10px 10px 0;
  background-color: rgba(12, 11, 9, 0.7);
  padding: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  }
`
