import { Squash as Hamburger } from 'hamburger-react'
import i18next from 'i18next'
import React from 'react'
import { useTranslation } from 'react-i18next'
import MobileNav from './Menu/Nav/MobileNav'

window.addEventListener('scroll', () => {
  const header = document.getElementById('header')
  if (window.scrollY > 100) {
    header.classList.add('header-scrolled')
  } else {
    header.classList.remove('header-scrolled')
  }
})

// eslint-disable-next-line react/prop-types
export default function Header({ isMobileNavOpen, setIsMobileNavOpen }) {
  const { t } = useTranslation()

  const onLanguageChange = (e) => {
    i18next.changeLanguage(e.target.value)
    if (e.target.value === 'he') {
      const elements = document.querySelectorAll('.i18n-he')
      for (const element of elements) {
        element.classList.add('i18n-he-active')
      }
    } else {
      const elements = document.querySelectorAll('.i18n-he')
      for (const element of elements) {
        element.classList.remove('i18n-he-active')
      }
    }
  }

  const toggleMobileNav = () => {
    setIsMobileNavOpen((isMobileNavOpen) => !isMobileNavOpen)
  }
  return (
    <header id="header" className="fixed-top d-flex align-items-center">
      <div className="container d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center justify-content-between">
          <h1 className="logo me-auto me-lg-0">
            <a href="index.html">BBQ HOUSE</a>
          </h1>
        </div>

        {/* <!-- Uncomment below if you prefer to use an image logo --> */}
        <a href="index.html" className="logo me-auto me-lg-0">
          <img src="assets/img/logo.png" alt="" className="img-fluid" />
        </a>

        <nav id="navbar" className="navbar order-last order-lg-0">
          <ul className="i18n-he">
            <li>
              <a className="nav-link scrollto active" href="#hero">
                {t('nav-btn-home')}
              </a>
            </li>
            <li>
              <a className="nav-link scrollto" href="#about">
                {t('nav-btn-about')}
              </a>
            </li>
            <li>
              <a className="nav-link scrollto" href="#menu">
                {t('nav-btn-menu')}
              </a>
            </li>
            <li>
              <a className="nav-link scrollto" href="#contact">
                {t('nav-btn-contact')}
              </a>
            </li>
          </ul>
          <i className="mobile-nav-toggle">
            <Hamburger toggled={isMobileNavOpen} toggle={toggleMobileNav} />
          </i>

          <div className="language-select">
            <select onChange={onLanguageChange}>
              <option value="en">{t('i18n-select-en')}</option>
              <option value="he">{t('i18n-select-he')}</option>
            </select>
          </div>
        </nav>
        {/* <!-- .navbar --> */}
      </div>
      {isMobileNavOpen && <MobileNav toggleMobileNav={toggleMobileNav} />}
    </header>
  )
}
