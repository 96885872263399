import React from 'react'
import { Button, Modal } from 'antd'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

const MenuModal = ({ itemOpen, onClose }) => {
  return (
    <>
      <StyledModal
        centered
        open={itemOpen}
        onCancel={onClose}
        footer={false}
        closeIcon={<StyledCloseButton>X</StyledCloseButton>}
      >
        <img
          style={{ width: '100%' }}
          src={`assets/img/menu/${itemOpen.category}/${itemOpen.item}.jpg`}
          alt=""
          className="img-fluid"
        ></img>
      </StyledModal>
    </>
  )
}
export default MenuModal

MenuModal.propTypes = {
  itemOpen: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
  onClose: PropTypes.func.isRequired
}

const StyledModal = styled(Modal)`
  .ant-modal-content {
    background: black;
  }
    width: 95vw !important;
    max-width: 1200px !important;
`
const StyledCloseButton = styled(Button)`
  background: black;
  color: white;
  font-weight: 600;
  &:hover {
    background: black;
    color: #cda45e !important;
    border-color: #cda45e !important;
  }
`
