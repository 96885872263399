import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import { Carousel } from 'react-responsive-carousel'
import React from 'react'

export default function AboutCarousel() {
  return (
    <Carousel
      autoPlay
      infiniteLoop
      showStatus={false}
      showArrows={false}
      showThumbs={false}
      showIndicators
      disableSwipe
      animationHandler="fade"
      interval={7000}
      transitionTime={3000}
    >
      <div>
        <img src="assets/img/about/about1.jpg" alt="" />
      </div>
      <div>
        <img src="assets/img/about/about2.jpg" alt="" />
      </div>
    </Carousel>
  )
}
