import React from 'react'
import { useTranslation } from 'react-i18next'

window.addEventListener('scroll', () => {
  const topbar = document.getElementById('topbar')
  if (window.scrollY > 100) {
    topbar.classList.add('topbar-scrolled')
  } else {
    topbar.classList.remove('topbar-scrolled')
  }
})

export default function TopBar() {
  const { t } = useTranslation()
  return (
    <div id="topbar" className="d-flex align-items-center fixed-top">
      <div className="container d-flex justify-content-center justify-content-between">
        <div className="contact-info d-flex align-items-center">
          <i className="bi bi-phone d-flex align-items-center">
            <span>04 - 6597225</span>
          </i>
          <i className="bi bi-clock d-flex align-items-center ms-4">
            <span> {t('opening-hours-header')}</span>
          </i>
        </div>
      </div>
    </div>
  )
}
