import React from 'react'
import { useTranslation } from 'react-i18next'
import AboutCarousel from './AboutCarousel'

export default function About() {
  const { t } = useTranslation()
  return (
    <section id="about" className="about i18n-he">
      <div className="container" data-aos="fade-up">
        <div className="row">
          <div
            className="col-lg-6 order-1 order-lg-2"
            data-aos="zoom-in"
            data-aos-delay="100"
          >
            <div className="about-img">
              <AboutCarousel />
            </div>
          </div>
          <div className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content">
            <div className="section-title">
              <h2>{t('about-title')}</h2>
              <p>{t('about-subtitle')}</p>
            </div>
            <p>{t('about-text1')}</p>
            <p>{t('about-text2')}</p>
            <p>{t('about-text3')}</p>
            <p>{t('about-text4')}</p>
          </div>
        </div>
      </div>
    </section>
  )
}
