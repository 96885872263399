import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'

import commonEn from './common/en.json'
import commonHe from './common/he.json'

export const translationsInit = () =>
  i18next.use(initReactI18next).init({
    resources: {
      en: {
        translation: commonEn
      },
      he: {
        translation: commonHe
      }
    },

    fallbackLng: 'en',
    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  })
