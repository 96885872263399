import { times } from 'lodash'
import React from 'react'
import { MENU_ITEMS } from '../../utils'
import MenuModal from '../MenuModal'
import MenuNav from './MenuNav'
import MenuItem from './MenuItem'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'

export default function Menu() {
  const { t } = useTranslation()
  const [MenuItemOpen, setMenuItemOpen] = React.useState(false)

  const isMobile = window.innerWidth < 768

  return (
    <section id="menu" className="gallery menu section-bg i18n-he">
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <h2>{t('menu-title')}</h2>
          <p>{t('menu-subtitle')}</p>
        </div>
        <MenuNav />
      </div>

      <div className="container-fluid" data-aos="fade-up" data-aos-delay="100">
        <StyledMenuContainer className="menu-container">
          {Object.values(MENU_ITEMS).map((category) => {
            if (!category.count) return null
            return times(category.count, (i) => {
              const item = i + 1
              return (
                <MenuItem
                  key={`${category.key}-${item}`}
                  item={item}
                  categoryKey={category.key}
                  setMenuItemOpen={setMenuItemOpen}
                />
              )
            })
          })}
        </StyledMenuContainer>
      </div>
      {!isMobile && (
        <MenuModal
          itemOpen={MenuItemOpen}
          onClose={() => setMenuItemOpen(false)}
        />
      )}
    </section>
  )
}

const StyledMenuContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`
