import React from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

export default function MenuItem({ categoryKey, item, setMenuItemOpen }) {
  const { t } = useTranslation()
  return (
    <GalleryItem
      onClick={() => setMenuItemOpen({ category: categoryKey, item })}
      className={`col-lg-3 col-md-4 col-sm-4 menu-item filter-${categoryKey}`}
      key={item}
    >
      <div className="gallery-item" style={{ position: 'relative' }}>
        <img
          src={`assets/img/menu/${categoryKey}/${item}.jpg`}
          alt=""
          className="img-fluid"
        ></img>

        <AbsoluteDiv>
          <Flex className="i18n-he">
            <p className="label">{t(`${categoryKey}${item}`)}</p>
            <p className="price">{t(`${categoryKey}${item}-price`)}</p>
          </Flex>
          <p className="ingredients">
            {t(`${categoryKey}${item}-ingredients`)}
          </p>
        </AbsoluteDiv>
      </div>
    </GalleryItem>
  )
}

// add props validation
MenuItem.propTypes = {
  categoryKey: PropTypes.string.isRequired,
  item: PropTypes.number.isRequired,
  setMenuItemOpen: PropTypes.func.isRequired
}

const GalleryItem = styled.div`
  cursor: pointer;
  display: flex;
`
const AbsoluteDiv = styled.div`
  position: absolute;
  bottom: 0;
  padding: 12px 16px;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  .label {
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 0;
  }
  .price {
    margin-bottom: 0;
  }
  .ingredients {
    font-size: 0.7rem;
  }
  .i18n-he-active {
    flex-direction: row-reverse;
  }
  >p {
    margin-bottom: 0;
  }
`

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  color: #cda45e;
`
